import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { classNames } from 'primereact/utils'
import React, {useEffect} from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { useAppSelector } from '../../../hooks/useAppSelector.hook'
import { StoreStatus } from '../../../common/types'
import { Message } from '../../atoms/Message'
import { SignInForm, SignInProps } from './SignIn.types'
import './SignIn.css';
import logo from '../../../assets/beyond-logo-login.png'
import { signOut } from "../../../features/Auth/Auth.service";

export const SignIn = ({
         formSubmit
     }: SignInProps) => {

    const { status  } = useAppSelector((state) => state.auth)

    const defaultValues: SignInForm = {
        email: '',
        password: '',
    }

    useEffect(() => {
        signOut();
    }, [])

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<SignInForm>({ defaultValues })

    const onSubmit = async (data: SignInForm) => {
        formSubmit(data);
        reset()
    }

    const getFormErrorMessage = (name: keyof SignInForm) => {
        return errors[name] && <small className="p-error">{errors[name]?.message}</small>
    }

    // @ts-ignore
    return (
        <div className="container log-in">
            <div className="flex">
                <div className="card p-card">
                    <img  alt="Beyond logo" className="login-logo" src={logo} />
                    <Message />
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className="field">
                          <span className="p-float-label">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required.',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address. E.g. example@email.com',
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        id={field.name}
                                        {...field}
                                        className={classNames({
                                            'p-invalid': fieldState.error?.message,
                                        })}
                                    />
                                )}
                            />
                            <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>
                              Email address
                            </label>
                          </span>
                            {getFormErrorMessage('email')}
                        </div>
                        <div className="field">
                          <span className="p-float-label">
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'Password is required.' }}
                                render={({ field, fieldState }) => (
                                    <Password
                                        feedback={false}
                                        id={field.name}
                                        autoComplete='current-password'
                                        {...field}
                                        className={classNames({
                                            'p-invalid': fieldState.error?.message,
                                        })}
                                        //header={passwordHeader}
                                        //footer={passwordFooter}
                                    />
                                )}
                            />
                            <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>
                              Password
                            </label>
                          </span>
                            {getFormErrorMessage('password')}
                        </div>

                        <Button type="submit" label="Log in" className="mt-2 beyond-button" loading={status === StoreStatus.Loading} />
                    </form>
                    <p className="mt-4 forgot-password">
                        <Link to="/forgot-password" className="ml-1 login-href">
                            Forgot password?
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    )
}
