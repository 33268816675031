import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import React, { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {Link, useNavigate} from 'react-router-dom'

import { useAppSelector } from '../../../hooks/useAppSelector.hook'
import { StoreStatus } from '../../../common/types'
import { forgotPassword } from '../../../features/Auth/Auth.service'
import { ForgotPasswordForm, ForgotPasswordProps } from './ForgotPassword.types'
import logo from "../../../assets/beyond-logo-login.png";
import './ForgotPassword.css'
import {Message} from "../../atoms/Message";
import {useAppDispatch} from "../../../hooks/useAppDispatch.hook";

export const ForgotPassword: FC<ForgotPasswordProps> = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { status } = useAppSelector((state: { auth: any }) => state.auth)

    const [formData, setFormData] = useState<ForgotPasswordForm>()
    const defaultValues: ForgotPasswordForm = {
        email: '',
    }

    useEffect(() => {
        if (status === StoreStatus.Succeeded  && formData && formData.email != defaultValues.email) {
            navigate('/reset-password', { state: formData })
        }
    }, [dispatch, formData, navigate, status])

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<ForgotPasswordForm>({ defaultValues })

    const onSubmit = (data: ForgotPasswordForm) => {
        setFormData(data)
        reset()
        dispatch(forgotPassword(data))
    }

    const getFormErrorMessage = (name: keyof ForgotPasswordForm) => {
        return errors[name] && <small className="p-error">{errors[name]?.message}</small>
    }

    return (
        <div className="container log-in">
            <div className="flex">
                <div className="card p-card">
                    <img  alt="Beyond logo" className="login-logo" src={logo} />
                    <Message />
                    <h2 className="text-center">Password recovery</h2>
                    <p className="small-text">Please enter your email address associated with your account and follow the instructions sent via email.</p>
                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                        <div className="field">
                              <span className="p-float-label">
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: 'Email is required.',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address. E.g. example@email.com',
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}
                                            {...field}
                                            className={classNames({
                                                'p-invalid': fieldState.error?.message,
                                            })}
                                        />
                                    )}
                                />
                                <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>
                                  Email address
                                </label>
                              </span>
                            {getFormErrorMessage('email')}
                        </div>

                        <Button type="submit" label="Send" className="mt-2 beyond-button" loading={status === StoreStatus.Loading} />
                    </form>
                    <p className="mt-4 forgot-password">
                        <Link to="/sign-in" className="ml-1 login-href">
                            Back
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    )
}
