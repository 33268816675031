import {useMemo} from "react";
import {useConfig} from "./useConfig.hook";
import {useParams} from "react-router-dom";
import useSWR from "swr";
import axios from "../common/axios";

interface DashboardResponse {
    id: string,
    title: string,
    enabled: boolean,
    default: boolean,
    parent?: string,
    type: 'metabase',
    metabase_id: number
}

export interface Dashboard extends Omit<DashboardResponse, 'parent' | 'enabled'> {
    children: Dashboard[]
}

const buildDashboardTree = (dashboards: DashboardResponse[]) => {
    const dashboardMap = new Map<string, Dashboard>()

    dashboards.forEach(dashboard => {
        dashboardMap.set(dashboard.id, {
            ...dashboard,
            children: []
        })
    })

    const rootDashboards: Dashboard[] = []

    dashboards
        .filter(dashboard => dashboard.enabled)
        .forEach(dashboard => {
            const currentDashboard = dashboardMap.get(dashboard.id)!
            if (dashboard.parent) {
                const parentDashboard = dashboardMap.get(dashboard.parent)
                if (parentDashboard) {
                    parentDashboard.children.push(currentDashboard)
                }
            } else {
                rootDashboards.push(currentDashboard)
            }
        })

    return rootDashboards;
}

const findDashboard = (dashboards: Dashboard[], dashboardId: string): Dashboard | undefined => {
    for (const dashboard of dashboards) {
        if (dashboard.id === dashboardId) {
            return dashboard
        }
        const child = findDashboard(dashboard.children, dashboardId)
        if (child) {
            return child
        }
    }
}

export const useDashboards = () => {
    const {userManagementApiBaseUrl} = useConfig()
    const {dashboardId} = useParams();

    const {data: dashboards, isLoading, error} = useSWR<DashboardResponse[]>(
        `${userManagementApiBaseUrl}/dashboards`,
        () => axios.get<DashboardResponse[]>(`${userManagementApiBaseUrl}/dashboards`)
            .then(response => response.data)
            .then(dashboards => dashboards.sort((a, b) => a.metabase_id < b.metabase_id ? -1 : 1))
    );

    const dashboardTree = useMemo(
        () => dashboards ? buildDashboardTree(dashboards) : undefined, [dashboards]);

    const defaultDashboard = useMemo(
        () => dashboardTree?.find(d => d.default), [dashboards]);

    const activeDashboard = useMemo(
        () => dashboardTree && defaultDashboard ? findDashboard(dashboardTree, dashboardId || defaultDashboard.id) : undefined,
        [dashboards, dashboardId, dashboardTree]);

    return {
        dashboards: dashboardTree,
        defaultDashboard,
        activeDashboard,
        isLoading,
        error
    }
}
