import React from "react";
import "../../styles/main.css"
import {signOut} from "../../features/Auth/Auth.service";
import {useAppSelector} from "../../hooks/useAppSelector.hook";
import {StoreStatus} from "../../common/types";
import {Button} from "react-bootstrap";
import SideMenu from "./SideMenu";
import {useAppDispatch} from "../../hooks/useAppDispatch.hook";

interface HeaderProps {
    imageSrcLogoPath: string;
}
const Header = ({imageSrcLogoPath}: HeaderProps) => {
    const dispatch = useAppDispatch()
    const {status} = useAppSelector((state: { auth: any }) => state.auth)
    const logUserOut = () => {
        dispatch(signOut()).then(() => window.location.reload())
    }

    return (
        <div className="header">
            <div className="width-33">
                <SideMenu/>
            </div>
            <div className="width-33">
                <a className="navbar-brand" href="/">
                    <img
                        src={imageSrcLogoPath}
                        className="d-inline-block align-center"
                        alt="Beyond"/>
                </a>
            </div>
            <div className="width-33">
                <Button
                    variant="outline-primary"
                    onClick={logUserOut}
                    className="sign-out"
                    disabled={status === StoreStatus.Loading}
                ></Button>{' '}
            </div>
        </div>
    );
}

export default Header;
