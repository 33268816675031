import React, {FC, useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'

import { SignInForm, SignInProps } from './SignIn.types'
import './SignIn.css';
import { Auth } from "@aws-amplify/auth";
import { NewPasswordChallenge } from "../NewPasswordChallenge/NewPasswordChallenge";
import { SignIn } from './SignIn'
import { NewPasswordChallengeForm } from "../NewPasswordChallenge/NewPasswordChallenge.types";
import {newPasswordRequiredError, signIn, signInError, signOut} from "../../../features/Auth/Auth.service";
import {useAppDispatch} from "../../../hooks/useAppDispatch.hook";

export const SignInCheck: FC<SignInProps> = () => {
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    const [userData, setUserData] = useState(false);
    const [email, setEmail] = useState();
    const [userAttr, setUserAttr] = useState(false);
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        signOut();
    }, [])
    useEffect(() => {
        dispatch({ type: 'RESET_STATE' });
    }, []);
    const handleLogin = (data: SignInForm) => {
        // @ts-ignore
        setEmail(data.email);
        Auth.signIn(data.email, data.password).then(results => {
            // todo reset password
            if (results.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setIsFirstLogin(true);
                setUserData(results);
                setUserAttr(userAttr);
            }
            if (!results.challengeName) {
                dispatch(signIn(results));
                navigate('/')
                setIsFirstLogin(false);
                setUserData(results);
                setUserAttr(userAttr);
            }
        }).catch(() => {
            dispatch(signInError(data))
        })
    };

    const changePassword = (data: NewPasswordChallengeForm) => {
        const newPwd = Auth.completeNewPassword(userData, data.newPassword);
        newPwd.then( () => {
            setIsFirstLogin(false);
            signOut();
        }).catch(() => {
            // @ts-ignore
            dispatch(newPasswordRequiredError({user: userData, newPassword: data.newPassword}))
        })
    }

    // @ts-ignore
    return (
        (isFirstLogin) ? (
            // @ts-ignore
            <NewPasswordChallenge formSubmit={changePassword} email={email} />
        ) : (
            <SignIn formSubmit={handleLogin} />
        )
    )
}
