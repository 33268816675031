import React, {FC} from 'react'
import {DashboardProps} from './Dashboard.types'
import {useConfig} from "../../../hooks/useConfig.hook";
import {Spinner} from "../../atoms/Spinner";
import {useMetabaseToken} from "../../../hooks/useMetabaseToken.hook";

const Dashboard: FC<DashboardProps> = () => {
    const metabaseToken = useMetabaseToken();
    const {metabaseBaseUrl} = useConfig();

    return metabaseToken ? (
        <div className="body-class flex align-items-start justify-content-center swarm">
            <iframe id="the-iframe"
                    title="Aspect Dashboard"
                    src={`${metabaseBaseUrl}/embed/dashboard/${metabaseToken}#bordered=false&titled=false`}
                    width="100%"
                    height="100%"
            />
        </div>
    ) : <Spinner />;
}

export default Dashboard
