import axios from "axios";
import {Auth} from "@aws-amplify/auth";

axios.interceptors.request.use(async config => {
    const session = await Auth.currentSession()
    const token = session?.getIdToken().getJwtToken()
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
});

export default axios;
