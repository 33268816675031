import { Auth } from '@aws-amplify/auth'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { CognitoUserAmplify } from '../../common/types'
import { ConfirmSignUpForm } from '../../components/pages/ConfirmSignUp/ConfirmSignUp.types'
import { ForgotPasswordForm } from '../../components/pages/ForgotPassword/ForgotPassword.types'
import { ResetPasswordForm } from '../../components/pages/ResetPassword/ResetPassword.types'
import { SignInForm } from '../../components/pages/SignIn/SignIn.types'
import { SignUpForm } from '../../components/pages/SignUp/SignUp.types'
import {NewPasswordChallengeForm} from "../../components/pages/NewPasswordChallenge/NewPasswordChallenge.types";

export const refreshToken = createAsyncThunk('auth/refreshToken', async () => {
    const cognitoUser: CognitoUserAmplify = await Auth.currentAuthenticatedUser()
    return cognitoUser
})

export const signUp = createAsyncThunk('auth/signUp', async ({ email, password, firstName, lastName }: SignUpForm) => {
    await Auth.signUp({
        username: email,
        password,
        attributes: { given_name: firstName, family_name: lastName },
    })
})

export const confirmSignUp = createAsyncThunk('auth/confirmSignUp', async ({ email, code }: ConfirmSignUpForm) => {
    return Auth.confirmSignUp(email, code)
})

export const resendSignUp = createAsyncThunk('auth/resendSignUp', async ({ email }: ConfirmSignUpForm) => {
    return Auth.resendSignUp(email)
})

export const signIn = createAsyncThunk('auth/signIn', async ( user: any) => {
    const cognitoUser: CognitoUserAmplify = user;
    return cognitoUser.attributes
})

export const signInError = createAsyncThunk('auth/signInError', async ({ email, password }: SignInForm) => {
    const cognitoUser: CognitoUserAmplify = await Auth.signIn(email, password);
    return cognitoUser.attributes
})

export const newPasswordRequiredError = createAsyncThunk('auth/newPasswordRequiredError', async ({user, newPassword}: NewPasswordChallengeForm) => {
    const cognitoUser: CognitoUserAmplify = await Auth.completeNewPassword(user, newPassword);
    return cognitoUser.attributes
})

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async ({ email }: ForgotPasswordForm) => {
    return await Auth.forgotPassword(email)
})

export const resendForgotPassword = createAsyncThunk('auth/resendForgotPassword', async ({ email }: ForgotPasswordForm) => {
    return await Auth.forgotPassword(email)
})

export const resetPassword = createAsyncThunk('auth/resetPassword', async ({ email, code, password }: ResetPasswordForm) => {
    return Auth.forgotPasswordSubmit(email, code, password)
})

export const signOut = createAsyncThunk('auth/signOut', async () => {
    return Auth.signOut()
})
