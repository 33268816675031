import { useState } from 'react';
import styles from './SideMenu.module.css'
import Offcanvas from 'react-bootstrap/Offcanvas';
import {MenuHamburgerIcon} from "../atoms/MenuHamburgerIcon";
import React, {useCallback} from "react";
import "../../styles/main.css"
import {Dashboard, useDashboards} from "../../hooks/useDashboards.hook";
import {useNavigate} from "react-router-dom";
import {FilteredDashboardSelector} from "../molecules/DashboardSelector";

const NavBar = () => {
    const navigate = useNavigate();
    const {dashboards, activeDashboard, isLoading} = useDashboards();

    const onDashboardSelect = useCallback((dashboard: Dashboard) => {
        navigate(`/dashboards/${dashboard.id}`);
    }, [navigate]);

    return (
        <nav className="navbar light bg-light">
            <div className="container-fluid">
                {!isLoading && activeDashboard && dashboards && <div className="navbar-collapse">
                    <FilteredDashboardSelector
                        dashboards={dashboards}
                        active={activeDashboard}
                        onDashboardSelect={onDashboardSelect}
                    />
                </div>}
            </div>
        </nav>
    );
}

const SideMenu = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <button onClick={handleShow} className={styles.menuBtn}>
                <MenuHamburgerIcon className={styles.menuBtnIcon}  />
            </button>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton className="shadow">
                    <Offcanvas.Title>Dashboards</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <NavBar />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default SideMenu;
