import {useConfig} from "./useConfig.hook";
import {useDashboards} from "./useDashboards.hook";
import useSWRImmutable from "swr/immutable";
import axios from "../common/axios";
import {useEffect, useMemo} from "react";

const ONE_MINUTE_MILLIS = 60 * 1000;

interface MetabaseJwtTokenResponse {
    token: string,
    expires_in: number,
    expires_at: number,
}

export const useMetabaseToken = () => {
    const {aspectReportingApiBaseUrl} = useConfig();
    const {activeDashboard, isLoading: isDashboardLoading} = useDashboards();
    const {data, mutate, isLoading: isTokenLoading } = useSWRImmutable<MetabaseJwtTokenResponse>(
        () => `${aspectReportingApiBaseUrl}?dashboardId=${activeDashboard!.metabase_id}`,
        (url: string) => axios.get(url).then((response) => {
            const res = response.data;
            return {
                ...res,
                expires_at: Date.now() + res.expires_in * 1000 - ONE_MINUTE_MILLIS
            }
        }),
    )

    // check whether the cached token (cached by SWR) is still valid, if not, make SWR to fetch a new one
    useEffect(() => {
        if (data && data.expires_at < Date.now()) {
            mutate()
        }
    }, [data])

    return useMemo(
        () => data && !isDashboardLoading && !isTokenLoading ? data.token : undefined,
        [data, isDashboardLoading, isTokenLoading]
    );
}
