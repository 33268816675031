import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Message } from '../../atoms/Message'
import { NewPasswordChallengeProps, NewPasswordChallengeForm } from './NewPasswordChallenge.types'
import { Password } from "primereact/password";
import './NewPasswordChallenge.css';
import logo from "../../../assets/beyond-logo-login.png";
import {Divider} from "primereact/divider";

export const NewPasswordChallenge = ({
        formSubmit,
        email
    }: NewPasswordChallengeProps) => {
    const defaultValues: NewPasswordChallengeForm = {
        email: email,
        newPassword: '',
    }

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<NewPasswordChallengeForm>({ defaultValues })

    const getFormErrorMessage = (name: keyof NewPasswordChallengeForm) => {
        return errors[name] && <small className="p-error">{errors[name]?.message}</small>
    }

    const passwordHeader = <h6>Pick a password</h6>
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one special char</li>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    )

    return (
        <div className="container log-in">
            <div className="flex">
                <div className="card p-card">
                    <img alt="Beyond logo" className="login-logo" src={logo} />
                    <h4 className="text-center">First login password change</h4>
                    <Message />
                    <form onSubmit={handleSubmit(formSubmit)} className="p-fluid">
                        <div className="field">
                          <span className="p-float-label">
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: 'Email is required.',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid email address. E.g. example@email.com',
                                    },
                                }}
                                render={({ field, fieldState }) => (
                                    <InputText
                                        id={field.name}
                                        {...field}
                                        disabled
                                        readOnly
                                        className={classNames({
                                            'p-invalid': fieldState.error?.message,
                                        })}
                                    />
                                )}
                            />
                            <label htmlFor="email" className={classNames({ 'p-error': !!errors.email })}>
                              Email address*
                            </label>
                          </span>
                            {getFormErrorMessage('email')}
                        </div>
                        <div className="field">
                          <span className="p-float-label">
                            <Controller
                                name="newPassword"
                                control={control}
                                rules={{ required: 'Password is required.' }}
                                render={({ field, fieldState }) => (
                                    <Password
                                        id={field.name}
                                        {...field}
                                        className={classNames({
                                            'p-invalid': fieldState.error?.message,
                                        })}
                                        header={passwordHeader}
                                        footer={passwordFooter}
                                    />
                                )}
                            />
                            <label htmlFor="new_password" className={classNames({ 'p-error': errors.newPassword })}>
                              Password
                            </label>
                          </span>
                            {getFormErrorMessage('newPassword')}
                        </div>

                        <Button type="submit" label="Confirm" className="mt-2 beyond-button" />
                    </form>
                </div>
            </div>
        </div>
    )
}
