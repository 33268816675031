export enum StoreStatus {
    Idle = 'Idle',
    Loading = 'Loading',
    Failed = 'Failed',
    Succeeded = 'Succeeded',
}

export interface CognitoAttributes {
    email: string
    email_verified: boolean
    given_name: string
    family_name: string
    sub: string
}

export interface CognitoUserAmplify {
    session: string
    authenticationFlowType: string
    challengeName: string
    username: string
    attributes: CognitoAttributes
}