import {Dashboard} from "../../hooks/useDashboards.hook";
import styles from './DashboardSelector.module.css';
import React, {useCallback, useMemo, useState} from "react";
import {MenuDashboardIcon} from "../atoms/MenuDashboardIcon";
import {MenuArrowIcon} from "../atoms/MenuArrowIcon";
import {MenuSearchIcon} from "../atoms/MenuSearchIcon";

const ThemeItem = ({id, theme, onDashboardSelect, active, allExpanded}: {
    id: string,
    theme: Dashboard,
    active: Dashboard,
    allExpanded: boolean,
    onDashboardSelect: (dashboard: Dashboard) => void,
}) => {
    const childActive = useMemo(() => {
        return theme.children.some((child) => child.id === active.id);
    }, [active, theme.children]);

    const [expanded, setExpanded] = useState<boolean | undefined>(undefined);

    const onToggle = useCallback(() => {
        setExpanded((expanded) => expanded === undefined ? !childActive : !expanded);
    }, [setExpanded, childActive]);

    return (
        <div id={id} className={expanded || allExpanded || (childActive && expanded === undefined) ? styles.expanded : ''}>
            <div
                className={`${styles.theme} ${theme.default ? styles.default : ''} ${active.id === theme.id ? styles.active : ''}`}>
                {theme.default ?
                    <MenuDashboardIcon className={styles.toggler} onClick={() => onDashboardSelect(theme)}/> :
                    <MenuArrowIcon className={styles.toggler} onClick={onToggle}/>}
                <span className={styles.label} onClick={() => onDashboardSelect(theme)}>
                    {theme.title}
                </span>
            </div>

            <ul className={styles.pillars}>
                {theme.children.map((pillar) => (
                    <li id={pillar.id} className={`${styles.pillar} ${active.id === pillar.id ? styles.active : ''}`}
                        onClick={() => onDashboardSelect(pillar)}>
                        {pillar.title}
                    </li>
                ))}
            </ul>
        </div>)
}

interface DashboardSelectorProps {
    dashboards: Dashboard[];
    active: Dashboard;
    allExpanded: boolean;
    onDashboardSelect: (dashboard: Dashboard) => void;
}


const applyFilter = (dashboardTree: Dashboard[], filter: string) => {
    const filterLower = filter.toLowerCase();
    const filterDashboard = (dashboard?: Dashboard): boolean => {
        return dashboard?.title.toLowerCase().includes(filterLower) || dashboard?.children.some((child) => filterDashboard(child)) || false;
    }
    const filterChildren = (children: Dashboard[]) => {
        return children.filter((child) => filterDashboard(child));
    }

    return filterChildren(dashboardTree.map((dashboard) => ({
        ...dashboard,
        children: filterChildren(dashboard.children)
    })))
}

const DashboardSelector = ({dashboards, ...props}: DashboardSelectorProps) => (
    <div className="container-fluid">
        {dashboards.map((dashboard) => (
            <ThemeItem id={dashboard.id} theme={dashboard} {...props}/>
        ))}
    </div>
)

export const FilteredDashboardSelector = ({dashboards, ...props}: Omit<DashboardSelectorProps, 'allExpanded'>) => {
    const [filter, setFilter] = React.useState('');
    const filteredDashboards = useMemo(() => dashboards && applyFilter(dashboards, filter), [dashboards, filter]);

    return (
        <div className="container-fluid">
            <div className={styles.search}>
                <MenuSearchIcon className={styles.searchIcon}/>
                    <input
                        className={`form-control ${styles.searchInput}`}
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    />
            </div>
            <DashboardSelector dashboards={filteredDashboards} {...props} allExpanded={!!filter}/>
        </div>
    )
}

