import {useEffect} from "react";
import {useAppSelector} from "../../hooks/useAppSelector.hook";
import {StoreStatus} from "../../common/types";
import {Outlet, useNavigate} from "react-router-dom";
import Header from "../organisms/Header";
import imagePathLogo from "../../assets/beyond-logo-navbar.png";

export const MainContainer = () => {
    const navigate = useNavigate()
    const {user, isAuthenticated, status} = useAppSelector((state: { auth: any }) => state.auth)

    useEffect(() => {
        if (!isAuthenticated && [StoreStatus.Succeeded, StoreStatus.Failed].includes(status)) {
            navigate('/sign-in')
        }
    }, [isAuthenticated, navigate, status, user]);

    return (
        <>
            <Header imageSrcLogoPath={imagePathLogo} />
            <Outlet/>
        </>
    )
}
