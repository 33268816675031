import 'bootstrap/dist/css/bootstrap.css'
import './styles/main.css'
import {useEffect} from 'react'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {refreshToken} from './features/Auth/Auth.service'
import {ForgotPassword} from './components/pages/ForgotPassword/ForgotPassword'
import Dashboard from './components/pages/Dashboard/Dashboard'
import {ResetPassword} from './components/pages/ResetPassword/ResetPassword'
import {SignOut} from './components/pages/SignOut/SignOut'
import {SignInCheck} from './components/pages/SignIn/SignInCheck';
import 'bootstrap/dist/js/bootstrap.bundle.js'
import {MainContainer} from "./components/templates/MainContainer";
import {SWRConfig} from "swr";
import {useAppDispatch} from "./hooks/useAppDispatch.hook";

function App() {
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(refreshToken())
    }, [dispatch])

    // @ts-ignore
    return (
        <SWRConfig>
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        {/* Auth routes */}
                        <Route path="/" element={<MainContainer/>}>
                            <Route index element={<Dashboard/>}/>
                            <Route path="/dashboards/:dashboardId" element={<Dashboard/>}/>
                        </Route>
                        {/* Non Auth routes */}
                        <Route path="/forgot-password" element={<ForgotPassword/>}/>
                        <Route path="/reset-password" element={<ResetPassword/>}/>
                        <Route path="/sign-out" element={<SignOut/>}/>
                        <Route path="/sign-in" element={<SignInCheck/>}/>
                        {/* Restrict other routes */}
                        <Route path="*" element={<Navigate to={'/'} replace/>}/>
                    </Routes>
                </BrowserRouter>
            </div>
        </SWRConfig>
    )
}

export default App
